<template>
  <div class="park">
    <Header />
    <div class="content">
      <div class="park-title">智慧园区解决方案</div>
      <p class="title-content">
        智慧园区数字化解决方案实现楼宇园区的数据融合，一体化管理以及智能应用。优化园区管理效率，提升企业、员工工作体验，保障园区安全。智慧园区数字化解决方案实现楼宇园区的数据融合，一体化管理以及智能应用。优化园区管理效率，提升企业、员工工作体验，保障园区安全。
      </p>
      <p class="park-content content1">
        智慧园区构建分为三大服务体系，通过分层建设，从内到外搭建可成长、可扩充，创造面向未来的智慧园区系统框架
      </p>
      <p class="park-content">
        1.为园区（管委会/管理公司）提供内部运营管理体系；
      </p>
      <p class="park-content">
        2.为园区（企业）提供园企服务体系；
      </p>
      <p class="park-content">
        3.为园区（企业及公众）提供综合社区服务体系。
      </p>
      <div style="text-align: center" class="img-box">
        <img
          class="content_img"
          src="https://aierbanjia-official-web.oss-cn-chengdu.aliyuncs.com/web/detail_yq.png"
          alt="智慧园区解决方案"
        />
      </div>
      <div class="city-technology">智慧园区解决方案</div>
      <div class="inside">
        <div class="inside-title">内部管理</div>
        <div class="list-item">
          <div v-for="(item,index) in list" :key="index" class="item">{{ item }}</div>
        </div>
      </div>
      <div class="inside">
        <div class="inside-title">对外服务</div>
        <div class="list-item">
          <div v-for="(item,index) in list1" :key="index" class="item">{{ item }}</div>
        </div>
      </div>
      <div class="footer" @click="jumpTo">下一篇：智慧交通解决方案 >></div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
export default {
  name: 'Park',
  components: { Header },
  data() {
    return {
      list:['智能综合安防', '智能停车管理', '智能能效管理', '智能资产管理', '智能能源管理'],
      list1:['人脸识别门禁', '访客人脸通行', '机器人门岗值守', '数据可视化', '机器人停车值守']
    }
  },
  methods: {
    jumpTo() {
      this.$router.push('/details/traffic')
    }
  }  
}
</script>

<style scoped>
.park-title{
  font-size: 0.37rem;
  font-weight: 400;
  color: #666666;
  text-align: center;
  margin-bottom: 0.7rem;  
}
.title-content {
  font-weight: 400;
  color: #666666;
  line-height: 0.64rem;
  font-size: 0.37rem;
  margin-bottom: 0.91rem;
  text-indent: 2em;
}
.park-content{
  text-indent: 2em;
  font-size: 0.37rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  line-height: 0.64rem;
}
.content1{
  margin-bottom: 1rem;
}
.img-box{
  margin-top: 1rem;
}
.content_img{
  width: 100%;
  height: 6.21rem;
}
.city-technology{
  margin-top: 1.31rem;
  margin-bottom: 0.97rem;
  width: 3.59rem;
  height: 0.37rem;
  font-size: 0.4rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #27201B;
}
.inside{
  margin-bottom: 0.3rem;
}
.inside-title{
  width: 2.11rem;
  height: 0.91rem;
  background: #FFAB0A;
  font-size: 0.4rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  line-height: 0.91rem;
  margin-bottom: 0.88rem;
}
.item{
  width: 2.91rem;
  height: 0.68rem;
  background: #FFFFFF;
  border: 1px solid #FFAB0A;
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #27201B;
  text-align: center;
  line-height: 0.68rem;
}
.list-item{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.item {
  flex: 0 0 30%;
  text-align: center;
  /* line-height:30px; */
  margin-right: 0.25rem;
  margin-bottom: 0.45rem;
}
.footer {
  cursor: pointer;
  margin-right: 0.2rem;
  text-align: right;
  width: 100%;
  height: 0.31rem;
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  margin-top: 0.2rem;
  margin-bottom: 0.6rem;
}
</style>